// components/layout/PageLayout.js
import { useUser } from "@auth0/nextjs-auth0/client";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import ErrorMessage from "@/components/feedback/ErrorMessage";
import LoadingState from "@/components/feedback/LoadingState";
import { useAccessTokenQuery } from "@/hooks/queries/useAccessTokenQuery";
import { useUserQuery } from "@/hooks/queries/useUserQuery";

const NavbarWithNoSSR = dynamic(() => import("@/components/layout/Navbar"), { ssr: false });
const SideDrawerWithNoSSR = dynamic(() => import("@/components/layout/SideDrawer"), { ssr: false });

export const PageLayout = React.memo(({ children }) => {
	const router = useRouter();
	const { pathname } = router;

	// Fetch user details using the useUser hook
	const { user } = useUser();

	const auth0UserId = user?.sub;

	// Fetch the access token using a custom hook
	const { data: accessToken } = useAccessTokenQuery();

	// Memoize the isSuperUser check as it depends on user data
	const isSuperUser = useMemo(() => {
		const namespace = process.env.NEXT_PUBLIC_AUTH0_NAMESPACE;
		return user && user[namespace]?.includes("superuser");
	}, [user]);

	const {
		data: userData,
		isLoading: isLoadingUserData,
		isError: isErrorUserData,
	} = useUserQuery({ accessToken, auth0UserId });

	if (isLoadingUserData) return <LoadingState />;
	if (isErrorUserData) return <ErrorMessage />;

	return (
		<div className="flex flex-col min-h-screen">
			{pathname !== "/login" && <NavbarWithNoSSR organization={userData?.organizations} />}
			<div className="flex flex-1">
				<SideDrawerWithNoSSR isSuperUser={isSuperUser} userProperties={userData?.organizations?.properties} />
				<main className="flex-1">{children}</main>
			</div>
		</div>
	);
});

PageLayout.displayName = "PageLayout";
