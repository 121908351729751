// src/services/getUserData.js
export const getUserData = async ({ auth0UserId, accessToken }) => {
	const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
	try {
		const response = await fetch(`${apiBaseUrl}/api/v1/users/${auth0UserId}`, {
			method: "GET",
			headers: { Authorization: `Bearer ${accessToken}` },
		});
		if (!response.ok) {
			const errorData = await response.json();
			throw new Error(errorData.message || "Failed to fetch user data");
		}
		return await response.json();
	} catch (error) {
		throw error; // Re-throw the error to handle it in useQuery's onError
	}
};
