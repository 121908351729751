// utils/logErrorToServer.js

/**
 * logErrorToServer
 *
 * This utility function is used to log errors to a secure server endpoint that requires user authentication.
 * It is specifically designed for scenarios where an access token is available and necessary to log errors
 * that may contain sensitive user or session-related information.
 *
 * When to use:
 * - Use this function when you have an access token available, typically in an authenticated user session.
 * - This function is suitable for logging errors that occur during user interactions in a secure, authenticated context.
 * - The server endpoint is protected, so the access token ensures that only authenticated requests are accepted.
 *
 * Specific Notes:
 * - This function filters out specific non-critical errors (e.g., known resource loading errors) to reduce noise in error logs.
 * - It automatically attaches the access token to the request headers and includes user-specific details like the Auth0 user ID.
 * - Errors are logged to the primary error logging endpoint: `/api/v1/errors`.
 */

import { shouldLogError } from "@/utils/errorLogDebouncer.js";

export const logErrorToServer = async ({ errorDetails, accessToken, auth0UserId }) => {
	const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

	if (!shouldLogError(errorDetails.message)) {
		return null;
	}

	try {
		const apiUrl = `${apiBaseUrl}/api/v1/errors`;
		const userAgent = errorDetails.userAgent || (typeof navigator !== "undefined" ? navigator.userAgent : "Unknown");
		const currentUrl = errorDetails.url || (typeof window !== "undefined" ? window.location.href : "Unknown");
		const domainName = "app.rentiq.com";

		// Array of specific error messages to check
		const specificErrors = [
			"Resource failed to load: https://app.rentiq.com/js/gtag.js",
			"Resource failed to load: https://app.rentiq.com/_next/static/chunks/195.12db2b6bd29fa77b.js", // Example additional error
			// Add more specific error messages here as needed
		];

		const isSpecificError = specificErrors.some((error) => errorDetails.message.includes(error));

		if (isSpecificError) {
			// Ignore this specific error
			return null;
		}

		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`, // Include the access token in the request
			},
			body: JSON.stringify({
				error_message: errorDetails.message,
				error_stack: errorDetails.stack,
				error_type: errorDetails.type,
				auth0_user_id: auth0UserId || null, // Use null if auth0UserId is not provided
				user_agent: userAgent, // Now safely defaults to 'Unknown' if not in a browser
				url: currentUrl, // Now safely defaults to 'Unknown' if not in a browser
				domain_name: domainName, // Include the domain name in the request
				additional_info: JSON.stringify(errorDetails.additionalInfo), // Ensure additional info is a stringified JSON object
			}),
		});

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}
		return await response.json();
	} catch (error) {
		// commented out the line below for production
		//console.error("Failed to log error to server:", error);
		return null; // Explicitly return null (for consistent-return)
	}
};
