// components/ErrorBoundary.js
import React from "react";
import ErrorMessage from "@/components/feedback/ErrorMessage";
import { fallbackLogErrorToServer } from "@/utils/fallbackLogErrorToServer";
import { logErrorToServer } from "@/utils/logErrorToServer";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	async componentDidCatch(error, errorInfo) {
		// Prepare error details for logging
		const userAgent = typeof navigator !== "undefined" ? navigator.userAgent : "Unknown";
		const currentUrl = typeof window !== "undefined" ? window.location.href : "Unknown";

		const errorDetails = {
			message: error.toString(),
			stack: error.stack,
			type: "React Component Error",
			userAgent: userAgent,
			url: currentUrl,
			additionalInfo: { componentStack: errorInfo.componentStack },
		};

		// Log the error to the server
		// Assuming props include accessToken and auth0UserId as part of AuthWrapper
		const { accessToken, auth0UserId } = this.props;

		try {
			if (accessToken && auth0UserId) {
				await logErrorToServer({ errorDetails, accessToken, auth0UserId });
			} else {
				await fallbackLogErrorToServer({
					message: errorDetails.message,
					stack: errorDetails.stack,
					type: errorDetails.type,
					auth0UserId: auth0UserId,
					additionalInfo: errorDetails.additionalInfo,
					userAgent: errorDetails.userAgent,
					url: errorDetails.url,
				});
			}
		} catch (err) {
			// Handle error silently
		}
		// Removed the return null statements as they are unnecessary
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;

		if (hasError) {
			// Render the custom error message component
			return <ErrorMessage />;
		}

		return children;
	}
}

export default ErrorBoundary;
