// src/hooks/queries/useAccessTokenQuery.js
import { useUser } from "@auth0/nextjs-auth0/client";
import { jwtDecode } from "jwt-decode";
import { useState, useCallback, useEffect, useRef } from "react";
import { fallbackLogErrorToServer } from "@/utils/fallbackLogErrorToServer";

export const useAccessToken = () => {
	const { user } = useUser();

	// Retrieves auth0UserId using useUser from Auth0, defaults to null if the user is not logged in (user is null).
	const auth0UserId = user?.sub || null;

	const refreshTimerId = useRef(null);
	const [isRefreshing, setIsRefreshing] = useState(false);

	const refreshAccessToken = useCallback(async () => {
		if (!user || isRefreshing) {
			return null;
		}

		setIsRefreshing(true);
		try {
			const refreshResponse = await fetch("/api/auth/getRefreshToken");
			const refreshData = await refreshResponse.json();
			if (!refreshResponse.ok) {
				throw new Error(refreshData.error || "Failed to refresh access token");
			}
			return refreshData.accessToken;
		} catch (error) {
			fallbackLogErrorToServer({
				message: error.message,
				stack: error.stack,
				type: "AccessTokenRefreshError",
				auth0UserId: auth0UserId,
				additionalInfo: { detail: "Failed during access token refresh" },
			});
			throw error;
		} finally {
			setIsRefreshing(false);
		}
		// We disable the exhaustive-deps rule here because including `isRefreshing` in the dependency array would cause an infinite loop.
		// The effect is intentionally dependent only on the `user` prop, as the refreshing mechanism should not re-trigger based on internal state changes like `isRefreshing`.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const fetchAccessToken = useCallback(async () => {
		if (!user) {
			return null; // Explicitly returning null when there is no user
		}
		try {
			const response = await fetch("/api/auth/getAccessToken");
			const data = await response.json();
			if (!response.ok) {
				if (response.status === 401) {
					return await refreshAccessToken();
				}
				throw new Error(data.error || "Failed to fetch access token");
			}
			return data.accessToken;
		} catch (error) {
			fallbackLogErrorToServer({
				message: error.message,
				stack: error.stack,
				type: "AccessTokenError",
				auth0UserId: auth0UserId,
				additionalInfo: {
					detail: "Error while fetching access token",
				},
			});
			return null;
		}
		// Include auth0UserId in the dependency array for completeness, even though it is derived from user
	}, [user, refreshAccessToken, auth0UserId]);

	useEffect(() => {
		if (!user) {
			return undefined; // Explicitly returning undefined when there is no user
		}
		const initiateProactiveRefresh = async () => {
			try {
				const token = await fetchAccessToken();
				if (token) {
					const decodedToken = jwtDecode(token);
					const exp = decodedToken.exp * 1000; // Convert to milliseconds
					const currentTime = Date.now();

					if (exp <= currentTime) {
						// Token has already expired
						await fallbackLogErrorToServer({
							message: "Token has expired. Please re-authenticate.",
							stack: "Token expiration check in initiateProactiveRefresh",
							type: "AccessTokenError",
							auth0UserId: auth0UserId,
							additionalInfo: { detail: "Token already expired during proactive refresh" },
						});
						return undefined; // Explicitly returning undefined when the token is expired
					}

					const refreshTime = exp - currentTime - 5 * 60 * 1000; // Refresh 5 minutes before expiry

					// Set timeout for proactive refresh
					const timerId = setTimeout(() => {
						refreshAccessToken();
					}, refreshTime);

					refreshTimerId.current = timerId; // Store the timer ID in useRef
				} else {
					// If token is null or undefined
					await fallbackLogErrorToServer({
						message: "Failed to fetch access token.",
						stack: "fetchAccessToken returned null",
						type: "AccessTokenError",
						auth0UserId: auth0UserId,
						additionalInfo: { detail: "Token is null or undefined during proactive refresh" },
					});
					return undefined; // Explicitly returning undefined when there is no token
				}
			} catch (error) {
				// Catch any other errors
				await fallbackLogErrorToServer({
					message: error.message,
					stack: error.stack,
					type: "AccessTokenError",
					auth0UserId: auth0UserId,
					additionalInfo: { detail: "Unexpected error during proactive refresh" },
				});
				return undefined; // Explicitly returning undefined when an error is caught
			}
			return undefined;
		};

		initiateProactiveRefresh();

		return () => {
			if (refreshTimerId.current) {
				clearTimeout(refreshTimerId.current); // Clear the timer on unmount
				refreshTimerId.current = null; // Reset the ref to null
			}
		};
		// Include auth0UserId in the dependency array for completeness, even though it is derived from user
	}, [fetchAccessToken, refreshAccessToken, user, auth0UserId]);

	return fetchAccessToken;
};
