// utils/fallbackLogErrorToServer.js

/**
 * fallbackLogErrorToServer
 *
 * This utility function is used to log errors to a fallback server endpoint that does not require user authentication.
 * It is intended for scenarios where an access token is not available, such as when a user is not authenticated or
 * when logging errors from public or unauthenticated parts of the application.
 *
 * When to use:
 * - Use this function when an access token is not available, such as in a public or unauthenticated context.
 * - This function is suitable for logging errors that occur in situations where authentication is not possible or necessary.
 * - The fallback endpoint is unprotected, allowing error logging without requiring user credentials.
 *
 * Specific Notes:
 * - Like the main logging utility, this function filters out specific non-critical errors to avoid unnecessary logs.
 * - It does not include an access token in the request headers, making it suitable for broader error logging scenarios.
 * - Errors are logged to a fallback endpoint: `/api/v1/fallback-errors`.
 * - This utility is a backup mechanism, ensuring that errors are still logged even when authentication is not available.
 */

import { shouldLogError } from "@/utils/errorLogDebouncer.js";

export const fallbackLogErrorToServer = async ({
	message,
	stack,
	type,
	auth0UserId,
	additionalInfo,
	userAgent,
	url,
}) => {
	const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
	const domainName = "app.rentiq.com";

	if (!shouldLogError(message)) {
		return null;
	}

	if (!message || !stack || !type) {
		// Can't hit POST below if we don't have key values
		return null;
	}

	// Array of specific error messages to check
	const specificErrors = [
		"Resource failed to load: https://app.rentiq.com/js/gtag.js",
		"Resource failed to load: https://app.rentiq.com/_next/static/chunks/195.12db2b6bd29fa77b.js",
		// Add more specific error messages here as needed
	];

	const isSpecificError = specificErrors.some((error) => message.includes(error));

	if (isSpecificError) {
		// Ignore this specific error
		return null;
	}

	try {
		const apiUrl = `${apiBaseUrl}/api/v1/fallback-errors`;

		const userAgentFinal = userAgent || (typeof navigator !== "undefined" ? navigator.userAgent : "Unknown");
		const urlFinal = url || (typeof window !== "undefined" ? window.location.href : "Unknown");

		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				// Does not require access token
			},
			body: JSON.stringify({
				error_message: message,
				error_stack: stack,
				error_type: type,
				auth0_user_id: auth0UserId || null, // Use null if auth0UserId is not provided
				user_agent: userAgentFinal, // Now safely defaults to 'Unknown' if not in a browser
				url: urlFinal, // Now safely defaults to 'Unknown' if not in a browser
				domain_name: domainName, // Include the domain name in the request
				additional_info: JSON.stringify(additionalInfo || {}), // Ensure additional info is a stringified JSON object
			}),
		});

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}
		return await response.json();
	} catch (error) {
		// commented out the line below for production
		// console.error("Failed to log error to server:", error);
		return null; // Explicitly return null (for consistent-return)
	}
};
