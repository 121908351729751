// src/hooks/useUserQuery.js
import { useQuery } from "@tanstack/react-query";
import { getUserData } from "@/services/getUserData";
import { logErrorToServer } from "@/utils/logErrorToServer";

export const useUserQuery = ({ auth0UserId, accessToken }) => {
	return useQuery({
		queryKey: ["userData", auth0UserId, accessToken],
		queryFn: () => getUserData({ auth0UserId, accessToken }),
		enabled: !!auth0UserId && !!accessToken,
		staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
		cacheTime: 5 * 60 * 1000, // 5 minutes in milliseconds
		onError: (error) => {
			const errorDetails = {
				message: error.message || "An unknown error occurred",
				stack: error.stack || "No stack trace available",
				type: "Fetch Error",
				additionalInfo: { detail: "Error while fetching user" },
			};
			logErrorToServer({ errorDetails, accessToken, auth0UserId });
		},
	});
};
