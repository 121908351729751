// components/AuthWrapper.js

/**
 * AuthWrapper serves as a dedicated component to handle authentication-related states and logic,
 * which is necessary due to the constraints of React's context and hook system. In Next.js applications,
 * hooks that depend on context provided by context providers (such as UserProvider) cannot be
 * directly invoked in the _app.js component where the providers themselves are being set up.
 * Attempting to use such hooks directly in _app.js leads to errors stating that the hooks must be used
 * within the components wrapped by the respective providers.
 *
 * To address this, AuthWrapper is used to encapsulate the use of these hooks in a sub-component that
 * is guaranteed to be within the scope of the necessary context providers (UserProvider and QueryClientProvider).
 * This structure avoids the "You forgot to wrap your app in <UserProvider>" error by ensuring that
 * useUser and useAccessTokenQuery are called within a component that is a child of UserProvider,
 * hence properly respecting the context scope required by these hooks.
 *
 * This component also manages loading and error states centrally for user and access token data,
 * enhancing the robustness and maintainability of the application's authentication flow.
 */

import { useUser } from "@auth0/nextjs-auth0/client";
import { useMemo } from "react";
import ErrorBoundary from "@/components/feedback/ErrorBoundary";
import ErrorMessage from "@/components/feedback/ErrorMessage";
import LoadingState from "@/components/feedback/LoadingState";
import { PageLayout } from "@/components/layout/PageLayout";
import { useAccessTokenQuery } from "@/hooks/queries/useAccessTokenQuery";

const AuthWrapper = ({ children }) => {
	const { user, isError: isErrorUser, isLoading: isLoadingUser } = useUser();
	const { data: accessToken, isError: isErrorAccessToken, isLoading: isLoadingAccessToken } = useAccessTokenQuery();

	const auth0UserId = useMemo(() => user?.sub, [user]);

	if (isLoadingUser || isLoadingAccessToken) {
		return <LoadingState />;
	}

	if (isErrorUser || isErrorAccessToken) {
		return <ErrorMessage />;
	}

	// Wrapping the application content in an ErrorBoundary to handle errors.
	return (
		<ErrorBoundary accessToken={accessToken} auth0UserId={auth0UserId}>
			<PageLayout>{children}</PageLayout>
		</ErrorBoundary>
	);
};

export default AuthWrapper;
