// hooks/useGlobalErrorHandler.js

/**
 * Custom hook to handle global error events in the browser.
 *
 * This hook sets up global error handlers using the `window.onerror` and `window.addEventListener('error', ...)` APIs.
 * It captures uncaught errors and resource loading errors, and logs them to the server using the `fallbackLogErrorToServer` utility function.
 *
 * - `handleWindowError`: Captures uncaught errors, providing details like message, stack trace, file source, and line/column numbers.
 * - `handleResourceError`: Captures resource loading errors, providing details like the source URL and tag name.
 * - Both handlers log error details, including user agent and current URL, to the server.
 * - The error handlers are attached when the component mounts and cleaned up when it unmounts.
 *
 * Usage:
 * - Import this hook and call it at the top level of your main component (e.g., `_app.js` in a Next.js application).
 *
 * Note:
 * - We intentionally do not use the `useUser` hook here to avoid violating React's rules of hooks, which require that hooks 
 *   be called unconditionally in the same order during every render. Since `useGlobalErrorHandler` might be used in various 
 *   contexts, including those where `useUser` might not be applicable or necessary, we've opted to exclude it from this hook.
 
 */

import { useEffect } from "react";
import { fallbackLogErrorToServer } from "@/utils/fallbackLogErrorToServer";

const useGlobalErrorHandler = () => {
	useEffect(() => {
		if (typeof window === "undefined") {
			return;
		}

		const handleWindowErrorEvent = (errorEvent) => {
			const errorDetails = {
				message: errorEvent.message,
				stack: errorEvent.error?.stack || "No stack trace available",
				type: "Window Error",
				additionalInfo: {
					source: errorEvent.filename,
					lineno: errorEvent.lineno,
					colno: errorEvent.colno,
				},
				userAgent: typeof navigator !== "undefined" ? navigator.userAgent : "Unknown",
				url: typeof window !== "undefined" ? window.location.href : "Unknown",
			};
			fallbackLogErrorToServer(errorDetails);
		};

		const handleResourceErrorEvent = (errorEvent) => {
			const errorDetails = {
				message: `Resource failed to load: ${errorEvent.target.src || errorEvent.target.href}`,
				stack: "No stack trace available",
				type: "Resource Error",
				additionalInfo: {
					tagName: errorEvent.target.tagName,
				},
				userAgent: typeof navigator !== "undefined" ? navigator.userAgent : "Unknown",
				url: typeof window !== "undefined" ? window.location.href : "Unknown",
			};
			fallbackLogErrorToServer(errorDetails);
		};

		window.onerror = handleWindowErrorEvent;
		window.addEventListener("error", handleResourceErrorEvent, true);

		return () => {
			window.onerror = null;
			window.removeEventListener("error", handleResourceErrorEvent, true);
		};
		// We disable the exhaustive-deps rule here because we intentionally want this useEffect to run only once on mount and cleanup on unmount.
		// The global error handler should not depend on any external variables or props, and adding unnecessary dependencies would cause redundant executions.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useGlobalErrorHandler;
