// components/BrowserNotSupported.js
const BrowserNotSupported = () => {
	return (
		<div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
			<p className="font-bold">Browser Not Supported</p>
			<p>Please update your browser to use our application.</p>
		</div>
	);
};

export default BrowserNotSupported;
