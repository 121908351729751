// src/hooks/queries/useAccessTokenQuery.js
import { useQuery } from "@tanstack/react-query";
import { useAccessToken } from "@/hooks/queries/useAccessToken";
import { fallbackLogErrorToServer } from "@/utils/fallbackLogErrorToServer";

export const useAccessTokenQuery = () => {
	const fetchAccessToken = useAccessToken();

	return useQuery({
		queryKey: ["accessToken"],
		queryFn: fetchAccessToken,
		staleTime: 0, // No stale time so we don't have expired tokens
		cacheTime: 5 * 60 * 1000, // 5 minutes in milliseconds
		onError: (error) => {
			//// auth0UserId is not included here to avoid redundancy, as errors with user context are already logged in useAccessToken
			fallbackLogErrorToServer({
				message: error.message,
				stack: error.stack,
				type: "QueryAccessTokenError",
				additionalInfo: { detail: "Error in React Query while fetching access token" },
			});
		},
	});
};
