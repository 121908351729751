// const LoadingState = () => {
// 	return (
// 		<div className="flex justify-center items-center space-x-2" aria-live="polite">
// 			<div className="w-4 h-4 bg-blue-600 rounded-full animate-bounce200"></div>
// 			<div className="w-4 h-4 bg-blue-600 rounded-full animate-bounce400 mx-1"></div>
// 			<div className="w-4 h-4 bg-blue-600 rounded-full animate-bounce600"></div>
// 		</div>
// 	);
// };

// export default LoadingState;

const LoadingState = () => {
	return <></>;
};

export default LoadingState;
